@media screen and (max-width: 950px) {
    .header__logo {
        width: 110px;
    }
    .nav__link, .header__action-link {
        margin-right: 30px;
    }
}

@media screen and (max-width: 900px) {
    .header__logo {
        width: 100px;
    }
    .header__content {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .nav, .header__actions {
        display: none;
    }
    .header__menu-btn {
        display: block;
    }
    /* Mobile Nav */
    .mobile-nav {
        display: flex;
    }
    .mobile-nav--show {
        transform: translateX(0%);
    }
    .mobile-nav__logo {
        margin-top: 5px;
        width: 120px;
    }
    /* Footer */
    .footer__infos {
        flex-wrap: wrap;
    }
    .footer__left, .footer__right {
        width: 100%;
    }
    .footer__left {
        text-align: center;
        margin-bottom: 20px;
    }
    .footer__right {
        justify-content: space-between;
    }
}

@media screen and (max-width: 800px) {
    /* Join Iddera */
    .join-iddera {
        font-size: 14px;
    }
    .join-iddera__heading {
        font-size: 3em
    }
    .join-iddera__avatar-cont:nth-of-type(1) {
        width: 60px;
        height: 60px;
        top: -57px;
        left: 30px;
    }
    .join-iddera__avatar-cont:nth-of-type(2) {
        width: 80px;
        height: 80px;
        top: -90px;
        right: 20px;
    }
    .join-iddera__avatar-cont:nth-of-type(3) {
        width: 95px;
        height: 95px;
        bottom: -110px;
        left: 25px;
    }
    .join-iddera__avatar-cont:nth-of-type(4) {
        width: 55px;
        height: 55px;
        bottom: -60px;
        right: 10px;
    }
    /* Reviews */
    .reviews {
        margin-top: 0;
        margin-bottom: 4pc;
    }
    .reviews__top {
        width: 85%;
    }
    .reviews__quotes {
        width: 60px;
        height: 60px;
    }
    .review__avrs {
        justify-content: flex-start;
        margin: 80px 0 30px;
    }
    .review__avrs .avatar-p {
        margin-left: 0;
        margin-right: 20px;
    }
    .reviews .carousel .slider {
        width: 85%;
    }
    /* Footer */
    .footer {
        font-size: 14px;
        margin-top: 4pc;
    }
    .footer__cards {
        flex-direction: column;
        align-items: center;
    }
    .footer__card {
        width: 80%;
        max-width: 400px;
        margin-bottom: 3pc;
        min-height: 480px;
        padding: 30px 45px;
    }
    .footer__infos {
        flex-direction: column;
        margin-bottom: 0;
    }
    .footer__left {
        margin-bottom: 10px;
    }
    .footer__right {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .footer__links {
        margin: 0 2pc 2pc 0;
        width: calc(50% - 2pc);
        min-width: 140px;
    }
    .footer__sm {
        margin-right: 30px;
    }
}

@media screen and (max-width: 500px) {
    /* Mobile Nav */
    .mobile-nav__action-btn {
        width: 100%;
        margin: 0 0 20px;
    }
    /* Join Iddera */
    .join-iddera__heading {
        font-size: 2.4em;
        line-height: 48px;
    }
    /* Reviews */
    .reviews__top, .reviews .carousel .slider {
        width: 100%;
    }
    .reviews__msg {
        font-size: 1.6em;
        line-height: 42px;
    }
    /* Footer */
    .footer__card {
        width: 100%;
        min-height: 420px;
        text-align: center;
    }
    .footer__card-heading {
        font-size: 2em;
        line-height: 42px;
    }
    .footer__card-desc {
        font: optional;
    }
    .footer__dwd-btns {
        justify-content: center;
    }
    .footer__dwd-btn {
        width: 100%;
    }
    .footer__card-btn-cont {
        justify-content: center;
    }
    .footer__links:last-of-type {
        width: 100%;
    }
    .footer__sms:not(.footer__sms--mobile) {
        display: none;
    }
    .footer__sms--mobile {
        display: flex;
        justify-content: space-evenly;
        margin-top: 0;
    }
    .footer__sm {
        margin-right: 0;
    }
}