.lrnp {
    font-size: 16px;
    color: #58459C;
    line-height: 34px;
    letter-spacing: -0.01em;
}

/* Learn Card */

.lrnp__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 30px;
    min-height: 410px;
    width: 33%;
    min-width: 300px;
    max-width: 350px;
    padding: 28px;
    cursor: pointer;
}

.lrnp__card:hover {
    background: #FFFFFF;
    box-shadow: 0px 20px 30px #FFF4FE;
    border-radius: 30px;
}

.lrnp__card-img-wrapper {
    width: 100%;
    height: 180px;
    margin-bottom: 20px;
}

.lrnp__card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 34px;
}

.lrnp__card-tag {
    font-size: 0.75em;
    padding: 7px 12px;
    margin-bottom: 12px;
}

.lrnp__card-title {
    font-family: 'General Sans', sans-serif;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 32px;
    letter-spacing: -0.03em;
    color: #3C268C;
    margin-bottom: 7px;
}

.lrnp__card-date {
    line-height: 32px;
    font-size: 1em;
}

/* Learn Summary */

.lrnp__post-summary {
    align-items: center;
    display: flex;
}

.lrnp__post-author {
    display: block;
    width: 80px;
    height: 80px;
    margin-right: 24px;
}

.lrnp__post-summary-mid {
    line-height: 19px;
    flex: 1
}

.lrnp__post-author-name {
    font-weight: 600;
    line-height: 19px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

/* Subscribe */

.lrnp__subscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    max-width: 600px;
    margin-top: 6pc;
    margin-bottom: 5pc;
}

.lrnp__subscribe-heading {
    font-family: 'General Sans', sans-serif;
    font-weight: 700;
    font-size: 2.5em;
    line-height: 48px;
    color: #3C268C;
    letter-spacing: -0.03em;
    margin-bottom: 20px;
}

.lrnp__subscribe-subheading {
    line-height: 32px;
    font-size: 1.1em;
    color: #58459C;
    width: 90%;
}

.lrnp__form {
    margin: 30px 0 20px;
    display: flex;
    width: 100%;
}

.lrnp__input {
    background: #FFFFFF;
    border: 2px solid #E6DFFF;
    box-sizing: border-box;
    box-shadow: 0px 20px 30px rgba(170, 149, 245, 0.05);
    border-radius: 15px;
    height: 70px;
    padding: 20px;
    margin-right: 20px;
    flex: 1;
    font-size: 1.1em;
}

.lrnp__input::placeholder {
    color: #7465AA;
}

.lrnp__subscribe-btn {
    padding: 16px 28px;
    border-radius: 15px;
    font-weight: 700;
    font-size: 1em;
    min-width: 150px;
    color: white;
    background: #7E5FF2;
}

/******** LEARN PAGE ***********/

/* Hero */

.lrnp__hero {
    margin-top: 4pc;
    margin-bottom: 4pc;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.lrnp__hero-content {
    width: 60%;
    position: relative;
}

.lrnp__hero-fog {
    pointer-events: none;
    position: absolute;
    width: 400px;
    left: -400px;
    top: 50%;
    transform: translateY(-50%);
}

.lrnp__hero-heading {
    font-family: 'General Sans', sans-serif;
    font-weight: 700;
    font-size: 3.4em;
    line-height: 72px;
    letter-spacing: -0.04em;
    color: #3C268C;
}

.lrnp__hero-subheading {
    font-weight: 500;
    font-size: 1.1em;
    line-height: 32px;
    margin: 34px 0;
}

/* Recent Post  */

.lrnp__post {
    display: flex;
}

.lrnp__post-img-wrapper {
    width: calc(45% - 80px);
    height: 380px;
    border-radius: 32px;
    margin-right: 80px;
}

.lrnp__post-right {
    width: 65%;
}

.lrnp__post-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 32px;
}

.lrnp__post-tag {
    margin: 10px 0;
}

.lrnp__post-heading {
    font-family: 'General Sans', sans-serif;
    font-weight: bold;
    font-size: 2em;
    line-height: 40px;
    color: #3C268C;
    letter-spacing: -0.03em;
    margin-bottom: 20px;
    max-width: 600px;
}

.lrnp__post-intro {
    font-weight: normal;
    font-size: 1em;
    line-height: 32px;
    max-width: 560px;
}

.lrnp__post .lrnp__post-summary {
    margin-top: 32px;
}

/* Learn Section */

.lrnp__section-cards {
    display: flex;
    flex-wrap: wrap;
}

.lrnp__section-heading {
    font-family: 'General Sans', sans-serif;
    font-weight: bold;
    font-size: 2em;
    color: #3C268C;
    letter-spacing: -0.01px;
    margin-left: 28px;
    margin-top: 5pc;
}

.lrnp__section-btn-wrapper {
    text-align: center;
    margin: 20px auto;
}

.lrnp__section-btn {
    font-weight: 600;
    font-size: 1.1em;
    line-height: 22px;
    color: #7E5FF2;
    background: transparent;
    padding: 10px;
}

/******** LEARN DETAILS PAGE ***********/

.lrndp__content {
    margin-top: 4pc;
    position: relative;
    max-width: 800px;
    padding-left: 100px;
}

.lrndp__fog {
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    height: 600px;
}

/* Nav */

.lrndp__nav {
    position: absolute;
    left: 15px;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 22px;
}

.lrndp__nav-sticky {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lrndp__nav-link {
    display: block;
    margin-bottom: 40px;
    text-align: center;
}

.lrndp__nav-sticky--mobile, .lrndp__nav-link--back-mobile {
    display: none;
}

.lrndp__nav-link--back, .lrndp__nav-link--back:hover, .lrndp__nav-link--back:active {
    color: #7E5FF2;
}

.lrndp__back-icon {
    display: inline-block;
    margin-right: 3px;
}

/* Post */

.lrndp__heading {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 64px;
    letter-spacing: -0.04em;
    color: #3C268C;
}

.lrndp__post-img-wrapper {
    width: 100%;
    height: 250px;
    margin: 40px 0;
}

.lrndp__post-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 40px;
}

.lrndp__post-heading, .lrndp__post-body h2, .lrndp__post-body h3 {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    letter-spacing: -0.04em;
    color: #3C268C;
    margin: 25px 0;
}

.lrndp__main-heading {
    font-weight: bold;
    font-size: 2.75em;
    line-height: 64px;
}

.lrndp__post-body {
    border-top: 0.5px solid #DFD9F7;
    border-bottom: 0.5px solid #DFD9F7;
    margin: 30px 0 20px;
    padding: 30px 0;
}

.lrndp__post-body h2 {
    font-weight: 500;
    font-size: 2em;
    line-height: 52px;
}

.lrndp__post-body h3 {
    font-weight: 500;
    font-size: 1.6em;
    line-height: 44px;
    margin-bottom: 10px;
}

.lrndp__post-body ul, .lrndp__post-body ol {
    list-style-position: inside;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 30px;
}

.lrndp__post-body .ul--bulleted {
    list-style-type: disc;
}

.lrndp__post-body .ol--bulleted {
    list-style-type: revert;
}

.lrndp__post-body li ul {
    margin-left: 30px;
}

.lrndp__txt-bold {
    font-weight: bold;
}

.lrndp__bq {
    margin: 30px 0;
    border-left: 2px solid #EB4CE4;
    padding-left: 41px;
}

.lrndp__bq p {
    font-weight: 500;
}

.lrndp__post-body p {
    margin-bottom: 24px;
}

.lrndp__post-body a, .lrndp__post-body a:hover, .lrndp__post-body a:active {
    color: #7E5FF2;
    text-decoration: underline;
}

/* More Section */

.lrndp__content .lrnp__card {
    width: 50%;
}

.lrndp__more-heading {
    text-align: center;
    font-family: 'General Sans', sans-serif;
    font-weight: bold;
    font-size: 2em;
    color: #3C268C;
    letter-spacing: 170%;
    margin-top: 3pc;
    margin-bottom: 3pc;
}