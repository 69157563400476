.legal {
    font-size: 16px;
    color: #5D4BA0;
    line-height: 34px;
    letter-spacing: -0.01em;
}

.legal__heading, .legal__doc h2:not(.legal__toc-heading), .legal__doc h3 {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    color: #3C268C;
}

/* Legal Top */

.legal__top {
    background: url(../../../../assets/images/fog-learn-2.png) no-repeat;
    background-size: 200px 500px;
}

.legal__header {
    padding: 50px;
    display: flex;
    justify-content: center;
}

.legal__top-body {
    margin: 3.5pc auto 2.7pc;
    max-width: 730px;
}

.legal__top-heading {
    font-size: 3.4em;
    line-height: 64px;
    letter-spacing: -0.04em;
    text-align: center;
    padding-bottom: 30px;
}

.legal__top-img-wrapper {
    margin: 0 auto;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.legal__bubbles-wrapper {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.legal__top-img {
    object-fit: contain;
    width: 170px;
    height: 170px;
}

/* Mobile sticky header */

.legal__sticky-header-mobile {
    background: transparent;
    backdrop-filter: blur(40px);
    position: fixed;
    width: 100%;
    padding: 0 28px 15px;
    z-index: 10;
    transform: translateY( -100%);
    transition: transform 0.4s, background 1s;
}

/*** Legal Content  ***/

.legal__content {
    display: flex;
    align-items: flex-start;
}

/* Legal Content - Nav  */

.legal__nav-wrapper {
    min-width: 170px;
    padding-right: 40px;
    border-right: 1px solid #ECE6FF;
}

.legal__nav-heading {
    font-size: 1.5em;
    margin-bottom: 24px;
    padding-top: 20px;
    letter-spacing: -0.02em;
}

.legal__nav {
    font-size: 0.9em;
    font-weight: 500;
}

.legal__nav-link {
    display: block;
    margin-bottom: 5px;
}

.legal__nav-link--active {
    font-weight: bold;
    color: #7E5FF2;
}

/* Legal Content - Bread crumbs  */

.legal__bcrumbs {
    display: none;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1em;
    color: #7464B3;
    line-height: 20px;
}

.legal__bcrumbs-current, .legal__bcrumbs svg {
    color: #3C268C;
    font-weight: 600;
}

/* Legal Content - Doc Heading  */

.legal__doc {
    padding-left: 48px;
    padding-top: 20px;
    min-width: 0;
    width: 100%;
}

.legal__doc-heading {
    font-size: 2.75em;
    margin-bottom: 12px;
    letter-spacing: -0.03em;
    line-height: 44px;
}

.legal__doc-edate {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 2pc;
}

/* Legal Content - TOC  */

.legal__toc-heading {
    font-weight: 500;
    font-size: 1.25em;
    line-height: 28px;
    margin-bottom: 15px;
}

.legal__toc-nav {
    color: #4824C1;
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 2pc;
}

.legal__toc-nav-link {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 8px;
}

/* Legal Content - Doc body  */

.legal__doc-body {
    margin-bottom: 7pc;
    line-height: 30px;
}

.legal__doc-body * {
    font-weight: inherit;
}

.legal__doc-section {
    margin-bottom: 5pc;
}

.legal__doc-body h2 {
    font-size: 1.9em;
    margin-bottom: 24px;
    letter-spacing: -0.04em;
    line-height: 42px;
}

.legal__doc-body h3 {
    font-size: 1.5em;
    margin-bottom: 24px;
}

.legal__doc-body h4 {
    font-size: 1.25em;
    margin-bottom: 16px;
    font-weight: 500;
    color: #3C268C;
}

.legal__doc-body p {
    margin-bottom: 24px;
    font-size: 1em;
    line-height: 30px;
}

.legal__doc-body a {
    color: #7E5FF2;
    font-weight: 500;
    text-decoration: underline;
}

.legal__doc-bold {
    font-weight: 600;
    color: #3C268C;
}

.legal__doc-body ul, .legal__doc-body ol {
    list-style-position: inside;
    font-weight: 500;
    margin-bottom: 24px;
    line-height: 30px;
}

.legal__doc-body ul {
    list-style-type: disc;
}

.legal__doc-body .table-wrapper {
    overflow: auto;
}

.legal__doc-body table {
    border-collapse: collapse;
    border: 1px solid #E4DDFF;
    line-height: 24px;
    margin-bottom: 24px;
}

.legal__doc-body th {
    font-weight: bold;
    font-size: 1em;
    padding: 12px 24px;
    border: 1px solid #E4DDFF;
}

.legal__doc-body td {
    font-size: 0.9em;
    padding: 12px 24px;
    border: 1px solid #E4DDFF;
    min-width: 170px;
    vertical-align: top
}

.legal__doc-body td p {
    line-height: inherit;
}

.legal__doc-body td ul {
    font-weight: 400;
    margin-bottom: 5px;
}

.legal__doc-body td li {
    margin-bottom: 5px;
}

.legal__doc-body td:nth-of-type(even) {
    background: #F8F6FF;
}

.legal__toggle-tbl-btn {
    padding: 11px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 0.5px solid #E4DDFF;
    border-radius: 4px;
    color: #7E5FF2;
    font-weight: 500;
    font-size: 1em;
    line-height: 24px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 400px;
}

.legal__toggle-tbl-btn .wicon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
}

.legal__toggle-tbl-btn svg {
    width: 10px;
    height: 10px;
    fill: #7E5FF2;
}

.legal__toggle-tbl-btn--open svg {
    transform: rotate(180deg);
}

/* Legal content - banner */

.legal__banner {
    background: #FFF7F7;
    border: 0.5px solid #FFC2C9;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 24px;
}

.legal__banner--no-heading {
    display: flex;
    align-items: flex-start;
}

.legal__banner--heading .legal__banner-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.legal__banner h5 {
    font-weight: 600;
    font-size: 1.1em;
    color: #EB4559;
}

.legal__banner svg {
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;
    color: #EB4559;
}

.legal__banner--no-heading svg {
    margin-top: 4px;
}

.legal__banner p {
    margin-bottom: 0;
}

/* Legal content - contact  */

.legal__contacts {
    display: flex;
    flex-wrap: wrap;
}

.legal__contact-section dt {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 8px;
}

.legal__contact {
    width: 50%;
    margin: 10px 0 30px;
    line-height: 32px;
}

/***** Specific Styles *****/