.faqp {
    font-size: 16px;
    color: #58459C;
    line-height: 34px;
    letter-spacing: -0.01em;
    background: url(../../../../assets/images/fog-hero.png) no-repeat;
    background-position: 50% left;
    background-size: 200px 600px;
}

.faqp__top {
    margin: 5pc 0 3pc;
    text-align: center;
}

.faqp__heading {
    font-family: "General Sans", sans-serif;
    font-weight: 700;
    color: #3C268C;
    max-width: 500px;
    margin: 24px auto;
    font-size: 3.6em;
    letter-spacing: -0.06em;
    line-height: 72px;
}

.faqp__subheading {
    font-weight: 500;
    font-size: 1.25em;
}

.faqp__group-heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    letter-spacing: -0.04em;
    font-size: 2em;
    line-height: 52px;
    color: #3C268C;
    padding-left: 10px;
}

.faqp__cards {
    margin-bottom: 3pc;
}

.faqp__card {
    padding: 40px 40px 0;
    background: #FFFFFF;
    border: 1px solid #FDF0FE;
    border-radius: 20px;
    margin-bottom: 10px;
}

.faqp__card-top {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 40px;
}

.faqp__icon-wrapper {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
    background: #F0E9FF;
    margin-left: 10px;
}

.faqp__question {
    font-weight: 500;
    font-size: 1.2em;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #3C268C;
}

.faqp__card--active .faqp__question {
    color: #7E5FF2;
}

.faqp__card-body {
    line-height: 26px;
    font-size: 1.1em;
    max-height: 0px;
    overflow: hidden;
    transition: all .2s linear;
}

.faqp__card--active .faqp__card-body {
    padding-bottom: 40px;
    max-height: unset;
}

.faqp__answer p {
    margin-bottom: 15px;
}

.faqp__answer ul {
    margin-top: 20px;
    margin-bottom: 20px;
}

.faqp__answer li {
    padding-left: 20px;
    position: relative;
}

.faqp__answer li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 11px;
    width: 5px;
    height: 5px;
    background-size: contain;
    background-image: url(../../../../assets/icons/bullet-point.svg);
}

/* Quote */

.faqp__quote {
    background: url(../../../../assets/images/pattern-about.png) no-repeat;
    background-position: 0% 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5pc 0;
    min-height: 60vh;
}

.faqp__quote-content {
    max-width: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.faqp__quote-text {
    font-family: "General Sans", sans-serif;
    font-weight: 500;
    font-size: 1.9em;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #3C268C;
    padding: 30px;
}

.faqp__quote-img {
    width: 70px;
}

.faqp__quote-img:first-of-type {
    margin-bottom: 1pc;
}

.faqp__quote-img:last-of-type {
    align-self: flex-end;
    margin-right: 3pc;
}