@media screen and (max-width: 800px) {
    .lp>* {
        font-size: 14px;
    }
    /* Hero */
    .hero__content {
        flex-direction: column;
        align-items: center;
    }
    .hero__left {
        width: 80%;
        margin-bottom: 3pc;
        margin-top: 0;
    }
    .hero__right {
        width: 70%;
        margin-right: 30px;
    }
    .hero__heading {
        font-size: 3.2em;
        line-height: 55px;
    }
    /* Who We Are */
    .wwa__download-btns .download-btn {
        width: 150px;
    }
    /* Gallery */
    .gallery {
        margin-top: 40px;
    }
    .gallery__img {
        height: 250px;
        width: 200px;
    }
    /* The Three Cs */
    .ccc__section {
        flex-direction: column;
    }
    .ccc__section:nth-of-type(2) {
        flex-direction: column-reverse;
    }
    .ccc__section-text {
        width: 80%;
        margin-bottom: 3pc;
    }
    .ccc__section-img-wrapper {
        width: 60%;
    }
    .ccc__section--cv .ccc__section-img-wrapper {
        margin-left: 0;
    }
    .ccc__cost {
        margin-top: 40px;
        padding: 40px 40px 100px;
    }
    .ccc__cost-back {
        flex-direction: column;
        align-items: flex-start;
    }
    .ccc__section-heading {
        margin: 15px 0;
    }
    .ccc__cost-btn {
        margin-top: 1.7pc;
        padding: 20px 40px;
    }
    .ccc__cost-desc {
        margin: 0;
    }
    /* Care */
    .care__top {
        flex-direction: column;
    }
    .care__top-img-wrapper {
        width: 60%;
    }
    .care__top-img {
        margin-bottom: 3pc;
    }
    .care__top-texts {
        width: 80%;
    }
    /* Get Started */
    .get-started__heading {
        margin-bottom: 40px;
    }
    .get-started__steps .sc:nth-of-type(even) {
        transform: none;
    }
    /* Membership benefits */
    .mbenefits__content {
        align-items: flex-start;
        flex-direction: column;
        padding-top: 3pc;
    }
    .mbenefits__desc {
        padding-top: 20px;
    }
    .mbenefits__slider {
        padding: 30px 0;
    }
    .mbenefits__slider-unit {
        font-size: 1.9em;
        margin: 0 1.6pc;
    }
    .mbenefits__slider svg {
        width: 30px;
        height: 30px;
    }
    .mbenefits__btn--left {
        display: none;
    }
    .mbenefits__btn--right {
        display: block;
    }
    /* Health Programs */
    .hprograms {
        padding: 4pc 0;
    }
    .hprograms__top {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
    }
    .hprograms__heading {
        max-width: unset;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 500px) {
    /* Hero */
    .hero {
        margin-top: 1.5pc;
    }
    .hero__content {
        text-align: center;
    }
    .hero__left {
        width: 100%;
    }
    .hero__right {
        width: 95%;
    }
    .hero__heading {
        font-size: 2.4em;
        line-height: 42px;
    }
    .hero__subheading {
        width: 95%;
        margin: 20px auto 25px;
        font-size: 1.1em;
    }
    .hero__inputs-wrapper {
        flex-direction: column;
    }
    .hero__input {
        width: 100%;
        margin-bottom: 1pc;
    }
    .hero__btn {
        width: 100%;
        height: 60px;
    }
    /* who We Are */
    .wwa__anim-wrapper {
        font-size: 1.4em;
        margin-bottom: 0;
    }
    .wwa__download-btns .download-btn {
        width: 130px;
    }
    /* The Three Cs */
    .ccc__section-heading {
        font-size: 2.3em;
        line-height: 42px;
    }
    .ccc__section-text {
        width: 100%;
    }
    .ccc__section-img-wrapper {
        width: 100%;
    }
    .ccc__cost-btn {
        padding: 20px 30px;
        min-width: 150px;
    }
    .ccc__cost-price {
        flex-wrap: wrap;
        justify-content: center;
    }
    .ccc__cost-duration {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }
    /* Care */
    .care {
        margin-bottom: 70px;
    }
    .care__heading {
        font-size: 2.4em;
        line-height: 42px;
    }
    .care__top-img-wrapper {
        width: 100%;
    }
    .care__top-texts {
        width: 100%;
    }
    .care__groups {
        margin-top: 80px;
    }
    .care__progress-cont, .care__group-heading:not(.care__group-heading--mobile) {
        display: none;
    }
    .care__group-heading--mobile {
        display: block;
        margin-bottom: 15px;
    }
    .care__group-content {
        margin-top: 3.5pc;
        opacity: 1;
        height: auto;
        visibility: unset;
    }
    .care__group-cards {
        justify-content: flex-start;
    }
    /* Get Started */
    .get-started__heading {
        font-size: 2.4em;
        line-height: 42px;
    }
    /* Membership Benefits */
    .mbenefits__slider {
        flex-direction: column;
        animation: none;
        height: auto;
        align-items: flex-start;
        padding-bottom: 30px;
    }
    .mbenefits__slider-unit {
        font-size: 1.5em;
        line-height: 44px;
        margin-bottom: 8px;
    }
    .mbenefits__slider-unit--hide {
        display: none;
    }
    .mbenefits__slider-text {
        white-space: normal;
    }
}