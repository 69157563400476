@media screen and (max-width: 800px) {
    .mlp__cards {
        flex-direction: column;
        align-items: center;
    }
    .mlp__card {
        width: 70%;
        margin-bottom: 2pc;
    }
}

@media screen and (max-width: 600px) {
    .lp--mlp .hero__heading {
        font-size: 3.1em;
        line-height: 50px;
    }
    .mlp__cards-heading {
        font-size: 2.3em;
        line-height: 44px;
        margin-top: 50px;
    }
    .mlp__card {
        width: 100%;
        padding: 50px 30px;
    }
}