@media screen and (max-width: 800px) {
    .prgp>* {
        font-size: 14px;
    }
    /* Hero */
    .prgp-hero__content {
        flex-direction: column;
        align-items: center;
    }
    .prgp-hero__left {
        width: 80%;
        margin-bottom: 3pc;
    }
    .prgp-hero__right {
        width: 60%;
        margin-right: 60px;
    }
    .prgp-hero__heading {
        font-size: 3.2em;
        line-height: 55px;
    }
    .prgp-hero__scroll {
        margin-top: 2pc;
        margin-bottom: 2pc;
    }
    /* Programs */
    .prgp__programs .carousel .slider {
        width: 60%;
    }
    .prgp__programs-btns {
        margin-right: 0;
        justify-content: center;
    }
    /* Program */
    .prgp__program-content {
        align-items: flex-start;
        flex-direction: column;
        padding-top: 3pc;
        padding-bottom: 3pc;
        width: 90%;
    }
    .prgp__program-texts {
        flex-direction: column;
    }
    .prgp__program-left {
        width: 100%;
    }
    .prgp__program-right {
        width: 100%;
    }
    .prgp__program-heading {
        margin-bottom: 10px;
        width: 100%;
    }
    .prgp__program-desc {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .prgp__program-right .prgp__program-btn {
        display: inline-block;
    }
    .prgp__program-left .prgp__program-btn {
        display: none;
    }
    /* Reviews */
    .prgp .reviews {
        margin-top: 4pc;
    }
}

@media screen and (max-width: 500px) {
    /* Hero */
    .prgp-hero {
        margin-top: 1.5pc;
    }
    .prgp-hero__content {
        text-align: center;
    }
    .prgp-hero__left {
        width: 100%;
    }
    .prgp-hero__right {
        width: 100%;
    }
    .prgp-hero__heading {
        font-size: 2.4em;
        line-height: 42px;
    }
    .prgp-hero__subheading {
        width: 95%;
        margin: 20px auto 25px;
        font-size: 1.1em;
    }
    .prgp-hero__scroll {
        justify-content: center;
    }
    /* Programs */
    .prgp__programs .carousel .slider {
        width: 95%;
    }
    .prgp__program-content {
        width: 100%;
    }
}