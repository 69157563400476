.prgp-hero {
    margin-top: 3pc;
    padding-bottom: 2pc;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../../../assets/images/fog-hero.png) no-repeat;
    background-position: 50% left;
    background-size: 200px 500px;
    position: relative;
}

.prgp-hero__content {
    display: flex;
    justify-content: space-between;
}

.prgp-hero__left {
    width: 50%;
}

.prgp-hero__right {
    width: 48%;
}

.prgp-hero__heading {
    font-family: 'General Sans', sans-serif;
    font-weight: 700;
    color: #3C268C;
    margin-top: 3.5pc;
    font-size: 3.6em;
    letter-spacing: -0.06em;
    line-height: 72px;
}

.prgp-hero__subheading {
    line-height: 32px;
    color: #58459C;
    margin: 20px 0 35px;
    width: 90%;
    max-width: 460px;
    font-weight: 500;
    font-size: 1.25em;
}

.prgp-hero__scroll {
    margin-top: 3pc;
    display: flex;
    align-items: center;
    color: #7E5FF2;
    font-weight: bold;
    font-size: 1em;
    line-height: 24px;
    letter-spacing: 0.05em;
}

.prgp-hero__scroll-icon {
    margin-left: 10px;
}

.prgp-hero__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 28px;
}

/* Programs */

.prgp__programs-names {
    margin-bottom: 1pc;
    cursor: grab;
}

.prgp__programs-name {
    display: inline-block;
    font-family: "General Sans", "sans-serif";
    font-weight: bold;
    font-size: 1.9em;
    line-height: 42px;
    letter-spacing: -0.04em;
    color: #3C268C;
    padding: 0 4px;
}

.prgp__programs .carousel .slider {
    width: 40%;
    margin: 0 auto;
}

.prgp__programs .carousel .slide {
    opacity: 0.2;
}

.prgp__programs .carousel .slide.selected {
    opacity: 1;
}

.prgp__programs-btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2pc;
    margin-right: 8pc;
}

.prgp__programs-btn {
    width: 50px;
    height: 50px;
    color: #3C268C;
    cursor: pointer;
    margin: 10px;
}

.prgp__programs-btn svg {
    width: 15px;
    height: 30px;
}

/* Program */

.prgp__program {
    font-size: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 550px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 1s ease;
}

.prgp__program-overlay {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: normal;
    z-index: 3;
}

.prgp__program-content {
    padding-top: 6pc;
    padding-bottom: 6pc;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    flex: 1;
    width: 80%;
}

.prgp__program-tag {
    background: rgba(177, 112, 188, 0.8);
    color: white;
    margin-bottom: 30px;
}

.prgp__program-texts {
    display: flex;
    justify-content: space-between;
}

.prgp__program-left {
    width: 60%;
}

.prgp__program-right {
    width: 40%;
}

.prgp__program-heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    width: 90%;
    max-width: 450px;
    margin-bottom: 40px;
    line-height: 36px;
    font-size: 1.6em;
}

.prgp__program-desc {
    font-family: "General Sans", sans-serif;
    line-height: 32px;
    color: #FFFFFF;
    max-width: 380px;
    font-size: 1.2em;
    font-weight: 500;
}

.prgp__program-btn {
    color: white;
    padding: 16px 28px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    font-weight: 600;
    font-size: 1.1em;
    position: relative;
    z-index: 2;
}

.prgp__program-right .prgp__program-btn {
    display: none;
}

/* Reviews */

.prgp .reviews {
    margin-top: 8pc;
}