/* Website Page */

.webp {
    padding-top: 80px;
}

/* Top Fog */

.top-fog {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: -1;
}

.top-fog--lp {
    background: linear-gradient(174.07deg, #FFEEFD 4.71%, rgba(244, 242, 254, 0) 91.36%);
}

.top-fog--ap {
    background: linear-gradient(174.07deg, #FFEEED 4.71%, rgba(244, 242, 254, 0) 91.36%);
}

.top-fog--prp {
    background: linear-gradient(174.07deg, #EDE8FF 4.71%, rgba(244, 242, 254, 0) 91.36%);
}

.top-fog--faqp {
    background: linear-gradient(174.07deg, #FFEEFD 4.71%, rgba(244, 242, 254, 0) 91.36%);
}

.top-fog--lrnp {
    background: linear-gradient(174.07deg, #FFF5EA 4.71%, rgba(244, 242, 254, 0) 91.36%);
}

.top-fog--prgp {
    background: linear-gradient(174.07deg, #EBFEFF 4.71%, rgba(244, 242, 254, 0) 91.36%);
}

.top-fog--legal-pp {
    background: linear-gradient(180deg, #FFD9FF 0%, rgba(255, 255, 255, 0) 49.96%);
}

.top-fog--legal-hp {
    background: linear-gradient(180deg, #FFE0E0 0%, rgba(255, 255, 255, 0) 49.96%);
}

.top-fog--legal-cp {
    background: linear-gradient(180deg, #D8F2FF 0%, rgba(255, 255, 255, 0) 49.96%);
}

.top-fog--legal-tc {
    background: linear-gradient(180deg, #D9CDFF 0%, rgba(255, 255, 255, 0) 49.96%);
}

/* Header, Nav */

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    transition: transform 0.4s, background 1s;
}

.header__content {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3C268C;
    font-size: 16px;
    line-height: 20px;
}

.scroll-down .header, .scroll-up .header {
    backdrop-filter: blur(10px);
    background: rgba(253, 252, 250, 0.9);
}

.scroll-down .header {
    transform: translate3d(0, -100%, 0);
}

.scroll-up .header {
    transform: none;
}

.nav {
    margin: 0 40px;
}

.nav, .header__actions {
    font-size: 1em;
    font-weight: 500;
}

.nav__link, .header__action-link {
    margin-right: 40px;
}

.nav__link:last-child {
    margin-right: 0;
}

.nav__link--active, .nav__link--active:hover, .nav__link--active:active {
    color: #7E5FF2;
}

.nav__link--dropdown {
    cursor: pointer;
    position: relative;
}

.nav__link svg {
    margin-left: 8px;
    fill: #3C268C;
}

.nav__link--active svg {
    fill: #7E5FF2;
}

.nav__link--dropdown-active svg {
    transform: rotate(180deg);
}

.nav__dlink-body {
    top: 40px;
    position: absolute;
    z-index: 100;
    background: #FFFFFF;
    border: 1px solid #E4DDFF;
    box-sizing: border-box;
    box-shadow: 0px 20px 30px rgba(60, 38, 140, 0.1);
    border-radius: 20px;
    padding: 24px 12px;
    width: 90vw;
    max-width: 580px;
    opacity: 0;
    pointer-events: none;
    will-change: opacity;
    transition: opacity 0.3s;
}

.nav__link--dropdown-active .nav__dlink-body {
    opacity: 1;
    pointer-events: unset;
}

.nav__dlink-title {
    font-weight: 500;
    font-size: 0.8em;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #B1A8D1;
    margin-bottom: 15px;
    padding-left: 8px;
}

.nav__dlinks {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 18px;
}

.nav__dlink {
    display: inline-block;
    color: #3C268C;
    margin: 5px 0;
    min-width: 250px;
}

.nav__dlink-text {
    display: inline-block;
    padding: 8px 15px;
}

.nav__dlink:hover .nav__dlink-text {
    background: #F6F3FF;
    border-radius: 12px;
    color: #7E5FF2;
}

.header__action-link {
    margin-right: 30px;
}

.header__action-btn {
    min-width: 130px;
}

.header__menu-btn {
    background: transparent;
    display: none;
}

.header__menu-cont {
    background: #7E5FF2;
}

/* Mobile Nav */

.mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: auto;
    background: #4824C1 url(../../../../assets/images/fog-mobile-nav.png) no-repeat;
    background-position: bottom right;
    background-size: 200px;
    padding: 32px;
    transform: translateX(130%);
    transition: all 0.5s ease-in;
    flex-direction: column;
}

.mobile-nav__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.mobile-nav__close {
    background: #7E5FF2;
    color: white;
}

.mobile-nav__links {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 29px;
    color: #FFFFFF;
    margin-bottom: 70px;
}

.mobile-nav__link {
    display: block;
    padding: 5px 0;
    margin-bottom: 25px;
}

.mobile-nav__link--dropdown svg {
    margin-left: 8px;
}

.mobile-nav__dlink-title {
    font-size: 0.6em;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #B1A8D1;
    margin-top: 30px;
}

.mobile-nav__dlink-body {
    max-height: 0px;
    overflow: hidden;
    will-change: max-height;
    transition: all .2s linear;
}

.mobile-nav__link--dropdown-active .mobile-nav__dlink-body {
    max-height: 100vh;
}

.mobile-nav__dlinks {
    font-size: 0.7em;
    font-weight: 500;
    line-height: 19px;
}

.mobile-nav__dlink {
    display: block;
    margin-top: 24px;
}

.mobile-nav__actions, .mobile-nav__action-btn {
    color: white;
    font-weight: 700;
    font-size: 1em;
}

.mobile-nav__action-btn {
    display: inline-flex;
    justify-content: center;
    padding: 16px 0;
    border-radius: 15px;
    min-width: 150px;
    width: calc(48% - 10px);
}

.mobile-nav__action-btn:first-child {
    background: #4B25C3;
    border: 1px solid #7E57FF;
    margin: 10px 20px 10px 0;
}

.mobile-nav__action-btn:last-child {
    background: #7E5FF2;
}

/* Reviews */

.reviews {
    font-size: 16px;
    background-size: 80px 60px;
    margin: 2pc auto 8pc;
}

.reviews__top {
    width: 70%;
    max-width: 750px;
    margin: 60px auto 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.reviews__quotes {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.reviews__avrs {
    display: flex;
    flex-wrap: wrap;
}

.reviews__avr-wrapper {
    width: 100px;
    height: 100px;
    margin: 20px;
}

.review__body {
    margin: 0 23px;
}

.reviews .carousel {
    cursor: grab;
}

.reviews .carousel .slider {
    max-width: 750px;
    width: 70%;
    margin: 0 auto;
}

.reviews .carousel .slide {
    text-align: left;
    position: relative;
}

.reviews .carousel .slide::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.reviews .carousel .slide::after {
    background: linear-gradient(93deg, rgba(255, 255, 255, 1) 41%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0.3) 90%);
}

.reviews .carousel .slide.selected::after {
    background: transparent;
}

.reviews .carousel .slide.selected+.slide::after {
    background: linear-gradient(93deg, rgba(255, 255, 255, 0.3) 10%, rgba(255, 255, 255, 0.5) 20%, rgba(255, 255, 255, 1) 60%);
}

.reviews__msg {
    font-family: "General Sans", sans-serif;
    font-weight: 500;
    letter-spacing: -0.03em;
    color: #3C268C;
    margin-bottom: 30px;
    font-size: 1.8em;
    line-height: 48px;
}

.reviews__author {
    font-weight: 500;
    font-size: 1.1em;
    line-height: 22px;
    letter-spacing: -0.03em;
    color: #6E5DAC;
    opacity: 0.9;
}

/* Join Iddera */

.join-iddera {
    background: #4824C1 url(../../../../assets/images/fog-join-iddera.png) no-repeat center center;
    background-size: 450px;
    min-height: 700px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.join-iddera__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.join-iddera__heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    font-size: 3.4em;
    line-height: 72px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    max-width: 600px;
    text-align: center;
    padding: 30px;
}

.join-iddera__btn {
    margin-top: 30px;
    font-size: 1.1em
}

.join-iddera__avatar-cont {
    position: absolute;
}

.join-iddera__avatar {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    z-index: 6;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.join-iddera__avatar-cont:nth-of-type(1) {
    width: 80px;
    height: 80px;
    top: -20%;
    left: -20%;
}

.join-iddera__avatar-cont:nth-of-type(2) {
    width: 110px;
    height: 110px;
    top: -20%;
    right: -20%;
}

.join-iddera__avatar-cont:nth-of-type(3) {
    width: 100px;
    height: 100px;
    bottom: -20%;
    left: -20%;
}

.join-iddera__avatar-cont:nth-of-type(4) {
    width: 85px;
    height: 85px;
    bottom: -20%;
    right: -20%;
}

.join-iddera__bubble {
    position: absolute;
    animation: bubbleUp 10s ease infinite;
    transform: all 0.2s ease;
}

.join-iddera__bubble:nth-of-type(1) {
    width: 40px;
    top: -50%;
    left: -50%;
}

.join-iddera__bubble:nth-of-type(2) {
    width: 20px;
    bottom: 30%;
    right: -50%;
}

.join-iddera__bubble:nth-of-type(3) {
    width: 50px;
    top: -30%;
    right: -50%;
}

.join-iddera__bubble:nth-of-type(4) {
    width: 35px;
    bottom: 30%;
    left: 20%;
}

.join-iddera__bubble:nth-of-type(5) {
    width: 35px;
    bottom: -50%;
    left: 65%;
}

.join-iddera__bubble:nth-of-type(6) {
    width: 20px;
    bottom: 50%;
    left: -50%;
}

.join-iddera__bubble:nth-of-type(7) {
    width: 50px;
    top: -50%;
    right: 50%;
    left: 50%;
}

.join-iddera__bubble {
    animation: flutter 10s infinite;
}

.join-iddera__bubble:nth-child(1) {
    animation-duration: 9s;
    animation-delay: 0.1s;
}

.join-iddera__bubble:nth-child(2) {
    animation-duration: 6s;
    animation-delay: 1.5s;
}

.join-iddera__bubble:nth-child(3) {
    animation-duration: 10s;
}

.join-iddera__bubble:nth-child(4) {
    animation-duration: 5.5s;
    animation-delay: 1.5s;
}

.join-iddera__bubble:nth-child(5) {
    animation-duration: 12s;
}

.join-iddera__bubble:nth-child(6) {
    animation-duration: 6s;
    animation-delay: 1s;
}

.join-iddera__bubble:nth-child(7) {
    animation-duration: 8s;
    animation-delay: 1s;
}

.join-iddera__bubble:nth-child(8) {
    animation-duration: 15s;
}

.join-iddera__bubble:nth-child(9) {
    animation-duration: 9s;
    animation-delay: 0.5s;
}

.join-iddera__bubble:nth-child(10) {
    animation-duration: 12s;
}

/* Footer */

.footer {
    font-size: 16px;
    margin-top: 6pc;
}

.footer__cards {
    display: flex;
    justify-content: space-evenly;
}

.footer__card {
    width: 45%;
    padding: 65px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 6pc;
}

.footer__card:first-of-type {
    background: #FDEFFF;
    color: #3C268C;
    padding-bottom: 0;
}

.footer__card:last-of-type {
    background: #7E5FF2;
    color: white;
}

.footer__card-heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    font-size: 2.5em;
    line-height: 48px;
    letter-spacing: -0.03em;
    margin-bottom: 35px;
}

.footer__card-desc {
    font-size: 1.1em;
    line-height: 32px;
}

.footer__card-img-cont, .footer__card-btn-cont {
    flex: 1;
    display: flex;
}

.footer__dwd-btns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer__dwd-btn {
    margin: 5px;
    width: 45%;
    max-width: 180px;
}

.footer__card-img-cont {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.footer__card-img {
    width: 140%;
    height: 100%;
    object-fit: contain;
}

.footer__card-btn-cont {
    align-items: flex-end;
}

.footer__card-btn {
    font-weight: bold;
    font-size: 1.2em;
    line-height: 24px;
    background: transparent;
    color: inherit;
}

.footer__card-btn-icon {
    margin-left: 5px;
}

.footer__infos {
    display: flex;
    font-family: "General Sans", sans-serif;
    color: #3C268C;
    font-size: 1em;
    line-height: 32px;
}

.footer__right {
    flex: 1;
}

.footer__logo {
    margin: 24px 0 15px;
}

.footer__links {
    display: flex;
    flex-direction: column;
    margin-left: 4pc;
    font-weight: 500;
    min-width: 150px;
}

.footer__links-heading {
    color: #8475BD;
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 32px;
}

.footer__link {
    margin-bottom: 24px;
    font-weight: 500;
    line-height: 21px;
}

.footer__link--contact {
    display: block;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-line;
}

.footer__contacts {
    font-weight: 500;
}

.footer__link--contact-bold {
    font-weight: 700;
}

.footer__link--contact svg {
    margin-right: 10px;
}

.footer__sms {
    margin-top: 36px;
}

.footer__sms--mobile {
    display: none;
    justify-content: center;
}

.footer__sm {
    display: inline-block;
    margin-right: 40px;
}

.footer__right {
    display: flex;
    justify-content: flex-end;
}

.footer__credits {
    padding-top: 4pc;
    margin-top: 6pc;
    font-size: 0.9em;
    color: #5D4BA0;
    line-height: 24px;
    background: #FCFCFF;
    border-top: 1px solid #ECE9F9;
}

.footer__credit {
    margin-bottom: 20px;
}

.footer__credit--bold {
    font-weight: 500;
}

.footer__credit-logo {
    margin: 0px auto;
    padding: 60px 0;
    display: block;
}