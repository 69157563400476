.g-heading {
    font-family: 'General Sans', sans-serif;
    opacity: 0.4;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: 600;
    font-size: 3.45em;
    letter-spacing: -0.03em;
    line-height: 72px;
    color: #3C268C;
    padding: 4pc 0;
}

.g-heading__text {
    margin: 0 10px 20px;
}

@media only screen and (max-width: 800px) {
    .g-heading {
        font-size: 3em;
        padding: 2pc 0;
    }
}

@media only screen and (max-width: 500px) {
    .g-heading {
        font-size: 2.5em;
        line-height: 62px;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .g-heading__text {
        margin-bottom: 34px;
    }
}