.prp {
    font-size: 16px;
    color: #58459C;
    line-height: 34px;
    letter-spacing: -0.01em;
    background: url(../../../../assets/images/fog-hero.png) no-repeat;
    background-position: 50% left;
    background-size: 200px 600px;
}

.prp__multicolor-text {
    background: -webkit-linear-gradient(180deg, #FF33C6 0%, #7433FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

.prp__top {
    margin: 5pc 0 2.3pc;
    text-align: center;
    margin-bottom: 70px;
}

.prp__heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    font-size: 3.6em;
    letter-spacing: -0.06em;
    line-height: 72px;
    color: #3C268C;
    margin: 24px auto;
    width: 100%;
}

.prp__subheading {
    width: 90%;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 500;
    font-size: 1.25em;
}

/* Plans */

.prp__plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 7pc;
    width: 100%;
}

.prp__plan-card {
    margin: 12px;
    padding: 35px;
    border-radius: 20px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: calc(50% - 24px);
    min-width: 360px;
    text-align: center;
    position: relative;
}

.prp__plan-card--pr {
    background: #4824C1 url(../../../../assets/images/pattern-cost.png) bottom left no-repeat;
    background-size: contain;
}

.prp__plan-card--fr {
    padding: 30px;
    align-items: center;
    justify-content: unset;
    background: #FAF9FF url(../../../../assets/images/pattern-cost-2.png) bottom left no-repeat;
    background-size: contain;
    color: #5D4BA1;
}

.prp__pop-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -80px;
    white-space: nowrap;
    display: flex;
}

.prp__pop-text {
    font-weight: 500;
    font-size: 1em;
    line-height: 24px;
    margin-left: -8px;
}

.prp__plan-heading {
    display: flex;
    align-items: center;
}

.prp__plan-icon-wrapper {
    width: 48px;
    height: 48px;
    margin-right: 10px;
    margin-bottom: 11px;
    background: linear-gradient(180deg, #FF33C6 0%, #7433FF 100%);
}

.prp__plan-name {
    display: flex;
    align-items: center;
    font-family: 'General Sans', sans-serif;
    letter-spacing: -0.02em;
    line-height: 28px;
    font-weight: bold;
    font-size: 1.4em;
}

.prp__plan-card--pr .prp__plan-name {
    color: white;
}

.prp__plan-card--fr .prp__plan-name {
    color: #3C268C;
}

.prp__plan-price {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px 0 15px;
}

.prp__plan-card--fr .prp__plan-price {
    color: #3C268C;
}

.prp__plan-amount {
    display: block;
    white-space: nowrap;
    font-family: 'General Sans', sans-serif;
    font-weight: 700;
    font-size: 2.75em;
    line-height: 52px;
}

.prp__plan-duration {
    font-size: 1.1em;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    opacity: 0.8;
    margin-left: 6px;
}

.prp__plan-card--pr .prp__plan-price-lbl {
    font-weight: 700;
    font-size: 0.9em;
    line-height: 32px;
    background: #B209CD;
    border-radius: 100px;
    padding: 0px 10px;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
}

.prp__plan-card--fr .prp__plan-price-lbl {
    font-weight: bold;
    font-size: 1.25em;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #3C268C;
    opacity: 0.8;
    margin-bottom: 10px;
}

.prp__plan-feats {
    margin: 25px 0 30px;
    text-align: left;
    flex: 1;
}

.prp__plan-feat {
    display: flex;
    align-items: flex-start;
    margin: 0 0 12px 20px;
}

.prp__bullet {
    margin-right: 14px;
    margin-top: 3px;
    color: #B209CD;
}

.prp__plan-feat-txt {
    font-size: 1.1em;
    line-height: 32px;
    font-weight: 500;
}

.prp__plan-btn {
    font-weight: 600;
    font-size: 1.1em;
    line-height: 22px;
    color: #FFFFFF;
    border-radius: 15px;
    width: 190px;
    padding: 18px 28px;
    margin: 0px 0 15px;
}

.prp__plan-card--pr .prp__plan-btn {
    background: #B209CD;
}

.prp__plan-card--fr .prp__plan-btn {
    background: #4824C1;
}

/* Membership Benefits Table */

.prp__mbenefits {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 70px 90px rgba(72, 36, 193, 0.05);
    border-radius: 20px;
    position: relative;
    padding: 40px;
    padding-bottom: 60px;
}

.prp__mbenefits-heading {
    font-family: 'General Sans', sans-serif;
    letter-spacing: -0.04em;
    line-height: 42px;
    font-weight: bold;
    font-size: 2.5em;
    margin-bottom: 50px;
    text-align: center;
    color: #3C268C;
}

.prp__mbenefits-table {
    border-collapse: collapse;
}

.prp__mbenefits-tbody {
    text-align: center;
}

.prp__mbenefits-tcell {
    padding: 32px 32px 30px;
    position: relative;
}

.prp__mbenefits-tcell:first-of-type {
    text-align: left;
    width: 50%;
}

.prp__mbenefits-tcell:nth-of-type(2) {
    background: #F7F6FF;
}

.prp__mbenefits-tcell--heading {
    font-weight: 700;
    font-size: 1.1em;
    line-height: 32px;
    color: #5D4BA0;
    padding: 16px 32px 0;
    text-align: center;
    min-width: 180px;
}

.prp__mbenefits-tcell--heading:nth-of-type(2) {
    color: #4824C1;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.prp__mbenefits-tbody tr:not(:last-of-type) .prp__mbenefits-tcell::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - 64px);
    left: 50%;
    transform: translateX(-50%);
    display: block;
    border-bottom: 2px dotted #E4DDFF;
}

.prp__mbenefits-tbody tr:last-of-type .prp__mbenefits-tcell {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.prp__mbenefit {
    display: flex;
    align-items: flex-start;
}

.prp__mbenefit-icon {
    margin-right: 30px;
    min-width: 44px;
    width: 44px;
    height: 44px;
}

.prp__mbenefit-icon svg {
    width: 24px;
    height: 24px;
    fill: #7E5FF2;
    color: #7E5FF2;
}

.prp__mbenefit-texts {
    flex: 1;
}

.prp__mbenefit-text {
    font-size: 1em;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #3C268C;
    display: block;
}

.prp__mbenefit-text--bold {
    font-weight: 600;
}

.prp__mbenefit-check {
    margin-left: 10px;
}

/* Insurance */

.prp__insurance {
    background: url(../../../../assets/images/pattern-page-section.png) no-repeat;
    background-position: 0% 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5pc 0;
    min-height: 60vh;
}

.prp__insurance-top {
    width: 80%;
    margin: 0 auto;
}

.prp__insurance-texts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.prp__insurance-heading {
    font-family: "General Sans", sans-serif;
    max-width: 370px;
    width: 50%;
    font-weight: bold;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #3C268C;
    font-size: 2.8em;
}

.prp__insurance-desc {
    width: 50%;
    font-size: 1.2em;
    font-weight: 500;
}

.prp__insurance-imgs {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 80%;
    margin: 50px auto;
    flex-wrap: wrap;
}

.prp__insurance-img-wrapper:nth-of-type(1) {
    width: 28%;
    height: 420px;
}

.prp__insurance-img-wrapper:nth-of-type(2) {
    width: 28%;
    height: 350px;
}

.prp__insurance-img-wrapper:nth-of-type(3) {
    width: 40%;
    height: 250px;
}

.prp__insurance-img {
    border-radius: 32px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}