@media only screen and (max-width: 800px) {
    .faqp__top {
        margin-top: 2pc;
    }
    .faqp__heading {
        line-height: 61px;
        font-size: 2.8em;
    }
    .faqp__group-heading {
        font-size: 1.7em;
    }
}

@media only screen and (max-width: 500px) {
    .faqp {
        font-size: 14px;
    }
    .faqp__heading {
        line-height: 48px;
        font-size: 2.8em;
    }
    .faqp__card {
        padding: 18px 24px 0;
    }
    .faqp__card-top {
        padding-bottom: 18px;
    }
    .faqp__quote {
        padding-top: 1pc;
    }
}