.lic {
    font-size: 16px;
    background: #FFFFFF;
    box-shadow: 0px 10px 15px #F7F4FF;
    border-radius: 20px;
    padding: 30px 40px;
    display: inline-flex;
    align-items: center;
}

.lic--last {
    background: #ECE6FF;
}

.lic__icon {
    margin-right: 20px;
    width: 24px;
    min-width: 24px;
}

.lic--last .lic__icon {
    display: none;
}

.lic__text {
    font-family: "General Sans", sans-serif;
    font-weight: normal;
    font-size: 1.25em;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #3C268C;
}

@media only screen and (max-width: 500px) {
    .lic {
        font-size: 14px;
    }
}