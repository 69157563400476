.lrnp-radio-list {
    margin: 12px 0;
    display: flex;
    flex-wrap: wrap;
}

.lrnp-radio__label {
    display: block;
    padding: 14px 22px;
    background: #E7E0FF;
    border-radius: 11.9377px;
    margin-right: 12px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    color: #3C268C;
    cursor: pointer;
    transition: all 0.5s ease;
}

.lrnp-radio__input {
    position: fixed;
    opacity: 0;
    width: 0;
}

.lrnp-radio__input:checked~.lrnp-radio__label {
    background: #EB4CE5;
    color: white;
}

@media only screen and (max-width: 500px) {
    .lrnp-radio-list {
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    .lrnp-radio__label {
        white-space: nowrap;
    }
}