.btn {
    color: white;
    background: black;
    min-width: 150px;
    padding: 16px 28px;
    border-radius: 15px;
    font-weight: 600;
    font-size: 1em;
}

.btn--co-cornflower-blue {
    background: #7E5FF2;
}

.btn--co-cornflower-blue:hover {
    background: #B209CD;
}

.btn--co-cosmos {
    color: #3C268C;
    background: #FFD0CF;
}

.btn--co-cosmos:hover {
    background: #FF9B9A;
}

.btn--co-dark-purple {
    background: #4824C1;
}

.btn--co-dark-purple:hover {
    background: #88079C;
}