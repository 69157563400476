@media only screen and (max-width: 800px) {
    /* Learn Summary */
    .lrnp__post {
        flex-direction: column;
        align-items: center;
    }
    .lrnp__post-img-wrapper {
        margin-bottom: 2pc;
        margin-right: 0;
        width: 80%;
    }
    .lrnp__post-right {
        width: 80%;
    }
    /******** LEARN PAGE ***********/
    /* Hero */
    .lrnp__hero {
        margin-top: 1pc;
    }
    .lrnp__hero-content {
        width: 80%;
        margin: 0 auto;
    }
    .lrnp__hero-heading {
        font-size: 3em;
        line-height: 61px;
    }
    .lrnp__hero-fog {
        left: -300px;
        width: 400px;
    }
    /* Section Cards */
    .lrnp__section-cards {
        justify-content: center;
    }
    /******** LEARN DETAILS PAGE ***********/
    .lrndp__content {
        margin-top: 1pc;
        padding-left: 26px;
    }
    /* Nav */
    .lrndp__nav {
        display: none;
    }
    .lrndp__nav-link--back-mobile {
        display: block;
        text-align: left;
        font-weight: 600;
    }
    .lrndp__nav-sticky--mobile {
        display: flex;
        position: static;
        flex-direction: row;
        justify-content: flex-start;
    }
    .lrndp__nav-link {
        margin-right: 24px;
    }
    /* Post */
    .lrndp__post-img--main {
        width: 100%;
    }
    .lrndp__post-heading--1 {
        font-size: 2.2em;
        line-height: 61px;
    }
}

@media only screen and (max-width: 500px) {
    .lrnp {
        font-size: 14px;
    }
    /* Learn Card */
    .lrnp__card {
        width: 100%;
    }
    /* Learn Summary  */
    .lrnp__post-img-wrapper {
        width: 100%;
    }
    .lrnp__post-right {
        width: 100%;
    }
    .lrnp__post-tag {
        margin-top: 0;
    }
    .lrnp__post-heading {
        font-size: 1.5em;
        margin-bottom: 0;
    }
    .lrnp__post-intro {
        font-size: 1.1em;
        line-height: 30px;
    }
    .lrnp__post .lrnp__post-summary {
        margin-top: 20px;
    }
    .lrnp__post-author {
        width: 55px;
        height: 55px;
        margin-right: 10px;
    }
    /* Subscribe */
    .lrnp__subscribe {
        width: 100%
    }
    .lrnp__form {
        flex-direction: column;
    }
    .lrnp__input {
        width: 100%;
        margin-bottom: 15px;
    }
    .lrnp__subscribe-btn {
        width: 100%;
        height: 60px;
    }
    /******** LEARN PAGE ***********/
    /* Learn Hero */
    .lrnp__hero-content {
        width: 100%;
    }
    /******** LEARN DETAILS PAGE ***********/
    .lrndp__fog {
        position: absolute;
        left: 0;
        top: -20px;
        width: 100px;
        height: 300px;
    }
    /* Nav */
    .lrndp__nav-link {
        margin-bottom: 10px;
    }
    /* Post */
    .lrndp__post-heading--1 {
        font-size: 2em;
        line-height: 32px;
        margin-top: 15px;
    }
    .lrndp__post-heading--2 {
        font-size: 1.7em;
        line-height: 32px;
    }
    .lrndp__para {
        font-size: 1.1em;
    }
}