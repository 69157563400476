.lp {
    overflow: hidden;
}

/* Bubbles Bg */

.bubbles-bg {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: transparent;
}

/* Hero */

.hero {
    margin-top: 3pc;
    padding-bottom: 3pc;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../../../assets/images/fog-hero.png) no-repeat;
    background-position: 50% left;
    background-size: 200px 500px;
    position: relative;
}

.hero__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.hero__left {
    margin-top: 1.5pc;
    width: 55%;
}

.hero__right {
    width: 40%;
    position: relative;
}

.hero__heading {
    font-family: 'General Sans', sans-serif;
    font-weight: 700;
    color: #3C268C;
    font-size: 3.6em;
    letter-spacing: -0.06em;
    line-height: 72px;
}

.hero__subheading {
    line-height: 32px;
    font-weight: 500;
    font-size: 1.25em;
    color: #58459C;
    margin: 20px 0 35px;
    width: 90%;
}

.hero__form {
    margin: 20px 0;
}

.hero__input-label {
    display: block;
    color: #240B78;
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 10px;
}

.hero__inputs-wrapper {
    display: flex;
}

.hero__input {
    background: #FFFFFF;
    border: 2px solid #E6DFFF;
    box-sizing: border-box;
    box-shadow: 0px 20px 30px rgba(170, 149, 245, 0.05);
    border-radius: 15px;
    height: 70px;
    padding: 20px;
    margin-right: 20px;
    flex: 1;
    font-size: 1.1em;
}

.hero__input::placeholder {
    color: #7465AA;
}

.hero__btn {
    font-weight: 700;
    min-width: 150px;
}

.hero__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 28px;
}

/* Who We Are */

.wwa {
    font-size: 16px;
    margin-top: 1pc;
}

.wwa__anim-wrapper {
    text-align: center;
    font-family: 'General Sans', sans-serif;
    color: #5842AC;
    font-size: 1.8em;
    line-height: 170%;
    font-weight: bold;
    margin-bottom: 4px;
}

.wwa__strike-word {
    position: relative;
    margin: 0 3px;
    display: inline-block;
}

.wwa__strike-word img {
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 100%;
    will-change: width;
    transition: all 0.4s ease;
}

.wwa__strike-enter {
    opacity: 0;
    width: 0;
    will-change: width, opacity;
    transition: all 0.5s ease 0.5s;
}

.wwa__anim-wrapper--active .wwa__strike-word img {
    width: 110%;
}

.wwa__anim-wrapper--active .wwa__strike-enter {
    width: 100%;
    opacity: 1;
}

.wwa__download-btns {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

/* Gallery */

.gallery {
    display: flex;
    width: fit-content;
    margin: 40px 50px 20px;
    animation: marquee 120s linear infinite;
}

.gallery__img {
    margin: 40px;
    border-radius: 32px;
    height: 320px;
    width: 270px;
    min-width: 270px;
    object-fit: cover;
    background-color: rgba(221, 221, 221, 0.5);
}

/* The Three Cs */

.ccc {
    font-size: 16px;
    margin-bottom: 200px;
}

.ccc .g-heading {
    margin: 0 auto 30px;
    width: 90%;
}

.ccc__section {
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}

.ccc__section-text {
    width: 35%;
    color: #5D4BA1;
}

.ccc__section-img-wrapper {
    width: 48%;
}

.ccc__section--cv .ccc__section-img-wrapper {
    margin-left: -80px;
}

.ccc__section-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 28px;
}

.ccc__section-heading {
    font-family: 'General Sans', sans-serif;
    font-weight: bold;
    font-size: 2.75em;
    letter-spacing: -0.04em;
    color: #3C268C;
    margin: 30px 0;
}

.ccc__section-desc {
    font-weight: 500;
    font-size: 1.2em;
    line-height: 32px;
}

.ccc__cost {
    margin-top: 150px;
    background: #B209CD;
    border-radius: 20px;
    padding: 80px;
    position: relative;
    margin-bottom: 150px;
}

.ccc__cost-back {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.ccc__cost-heading {
    color: inherit;
}

.ccc__cost-desc {
    max-width: 400px;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 32px;
    margin: 0 20px;
}

.ccc__cost-btn {
    padding: 24px 0px;
    min-width: 200px;
    font-size: 1.1em;
}

.ccc__cost-front {
    position: absolute;
    background: url(../../../../assets/images/pattern-cost-lp.png) #4824C1 bottom left no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 220px;
    width: 85%;
    bottom: -110px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 23px;
    color: white;
    font-size: 1.1em;
    transition: transform 0.3s ease;
}

.ccc__cost-front:hover {
    transform: translateX(-50%) scale(0.95);
}

.ccc__cost-price {
    margin: 15px 0;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
}

.ccc__cost-currency {
    color: #EAB8BD;
    font-weight: normal;
    font-size: 1.9em;
}

.ccc__cost-amount {
    font-family: 'General Sans', sans-serif;
    font-weight: 700;
    font-size: 3.2em;
    line-height: 60px;
    margin: 0 12px;
}

.ccc__cost-duration {
    color: rgba(255, 255, 255, 0.8);
}

.ccc__cost-tag {
    font-weight: 700;
    font-size: 0.8em;
    line-height: 32px;
    background: #B209CD;
    border-radius: 100px;
    padding: 0px 10px;
    letter-spacing: 0.06em;
}

/* Care  */

.care {
    font-size: 16px;
    margin-top: 100px;
    margin-bottom: 120px;
}

.care__heading {
    font-family: 'General Sans', sans-serif;
    font-weight: bold;
    font-size: 2.75em;
    letter-spacing: -0.03em;
    color: #3C268C;
    line-height: 60px;
    margin-bottom: 50px;
}

.care__top {
    font-size: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.care__top-img-wrapper {
    width: 50%;
    position: relative;
}

.care__bubble {
    position: absolute;
    top: -10px;
    left: -15px;
    width: 60px;
    z-index: -1;
}

.care__top-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 32px;
}

.care__top-texts {
    width: 40%;
}

.care__top-heading {
    font-family: 'General Sans', sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    color: #3C268C;
    line-height: 170%;
    margin-bottom: 35px;
}

.care__top-desc {
    font-weight: normal;
    line-height: 32px;
    color: #5D4BA1;
    font-weight: 500;
    font-size: 1.2em;
    letter-spacing: -0.02em;
}

.care__progress-cont {
    margin-top: 80px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.care__progress-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 10px;
    background-color: #3C268C;
    border-radius: 50%;
}

.care__progress-circle--active {
    background-color: #D834D7;
    position: relative;
}

.care__progress-circle--active::after {
    content: "";
    width: 35px;
    height: 35px;
    position: absolute;
    top: -8px;
    left: -8px;
    background: url(../../../../assets/images/partial-ellipse.svg) no-repeat;
    background-size: 25px;
}

.care__group-headings {
    display: flex;
    justify-content: space-evenly;
}

.care__group-heading {
    opacity: 0.5;
    line-height: 60px;
    font-size: 2.6em;
}

.care__group-heading--active {
    opacity: 1;
}

.care__group-heading--mobile {
    display: none;
    opacity: 1;
}

.care__group-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
}

.care__group-content--active {
    opacity: 1;
    height: auto;
    visibility: unset;
}

.care__group-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0px;
}

.care__group-cards .lic {
    margin: 14px;
}

/* Get Started */

.get-started {
    font-size: 16px;
    margin-bottom: 4pc;
}

.get-started__heading {
    font-family: 'General Sans', sans-serif;
    font-weight: bold;
    color: #3C268C;
    margin: 0 auto;
    letter-spacing: -0.03em;
    text-align: center;
    line-height: 60px;
    font-size: 2.75em;
    max-width: 550px;
}

.get-started__steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.get-started__steps .sc:nth-of-type(even) {
    transform: translateY(40px);
}

/* Membership Benefits */

.mbenefits {
    font-size: 16px;
    background: url(../../../../assets/images/bg-benefits.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 620px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.mbenefits::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    mix-blend-mode: normal;
    z-index: 3;
    background: #7B0A8D;
    opacity: 0.75;
}

.mbenefits__content {
    padding-top: 6pc;
    padding-bottom: 1pc;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    z-index: 10;
    flex: 1;
    width: 100%;
}

.mbenefits__tag {
    background: rgba(177, 112, 188, 0.8);
    color: white;
    margin-bottom: 20px;
}

.mbenefits__heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    line-height: 60px;
    font-size: 2.8em;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    max-width: 300px;
}

.mbenefits__desc {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 40px;
    color: #FFFFFF;
    max-width: 450px;
    padding-top: 60px;
    margin-bottom: 20px;
}

.mbenefits__btn {
    position: relative;
    z-index: 2;
    margin-top: 35px;
}

.mbenefits__btn--right {
    display: none;
}

.mbenefits__slider {
    display: flex;
    flex-wrap: nowrap;
    padding: 50px 0;
    animation: marquee 180s infinite linear;
    position: relative;
    z-index: 10;
    width: fit-content;
}

.mbenefits__slider-unit {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    font-size: 2.5em;
    line-height: 74px;
    display: inline-flex;
    flex-wrap: nowrap;
    width: auto;
    align-items: center;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 0 4pc;
}

.mbenefits__slider-unit svg {
    width: 40px;
    height: 40px;
}

.mbenefits__slider-text {
    white-space: nowrap;
    display: inline-block;
    margin-left: 20px;
}

/* Health Programs */

.hprograms {
    font-size: 16px;
    background: transparent url(../../../../assets/images/pattern-health-programs.png) no-repeat;
    background-size: cover;
    padding: 7pc 0;
}

.hprograms__top {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 48px;
}

.hprograms__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.hprograms__list .lic {
    margin: 10px 25px;
    padding: 40px;
}

.hprograms__heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    color: #3C268C;
    max-width: 300px;
    font-size: 3em;
    line-height: 60px;
    letter-spacing: -0.06em;
}

.hprograms__subheading {
    line-height: 32px;
    font-weight: 500;
    font-size: 1.1em;
    color: #5D4BA1;
    max-width: 400px;
}

.hprograms__btn {
    font-size: 1.1em;
    display: block;
    margin: 60px auto 0;
}