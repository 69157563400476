@media only screen and (max-width: 800px) {
    /* Pricing */
    .prp__top {
        margin-top: 2pc;
        margin-bottom: 6pc;
    }
    .prp__heading {
        line-height: 61px;
        font-size: 2.8em;
    }
    .prp__mbenefits-list {
        padding: 40px 20px;
    }
    /* Plans */
    .prp__plans {
        margin-bottom: 4pc;
    }
    .prp__plan-card {
        width: 100%;
        min-width: unset;
    }
    .prp__plans-icon-wrapper {
        width: 38px;
        height: 38px;
    }
    .prp__plans-icon-wrapper svg {
        width: 20px;
        height: 20px;
    }
    /* Membership plans table */
    .prp__mbenefits {
        padding: 20px;
    }
    .prp__mbenefits-table {
        display: block;
        overflow-x: auto;
    }
    .prp__mbenefits-tcell:first-of-type {
        min-width: 350px;
        padding-left: 0;
    }
    .prp__mbenefits-tcell {
        padding-left: 16px;
        padding-right: 16px;
    }
    /* Insurance */
    .prp__insurance-texts {
        flex-direction: column;
    }
    .prp__insurance-heading, .prp__insurance-desc {
        width: 100%;
    }
    .prp__insurance-heading {
        max-width: unset;
        font-size: 2.3em;
        margin-bottom: 15px;
    }
    .prp__insurance-img-wrapper {
        margin-top: 30px;
    }
    .prp__insurance-img-wrapper:nth-of-type(1) {
        width: 48%;
        height: 320px;
    }
    .prp__insurance-img-wrapper:nth-of-type(2) {
        width: 48%;
        height: 250px;
    }
    .prp__insurance-img-wrapper:nth-of-type(3) {
        width: 100%;
        order: -1;
    }
}

@media only screen and (max-width: 500px) {
    /* Pricing */
    .prp {
        font-size: 15px;
    }
    .prp__heading {
        line-height: 48px;
        font-size: 2.6em;
    }
    .prp__cost-price {
        font-size: 2.6em;
        margin: 0;
    }
    .prp__mbenefits {
        width: 100%;
    }
    .prp__mbenefit {
        align-items: flex-start;
    }
    .prp__mbenefit-icon {
        margin-top: 20px;
        margin-right: 15px;
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
    .prp__mbenefit-text {
        font-size: 0.9em;
    }
    .prp__mbenefit-text--bold {
        font-size: 1.05em;
    }
    /* Plans */
    .prp__plan-card {
        padding: 24px;
        margin-left: 0;
        margin-right: 0;
    }
    .prp__mbenefits-heading {
        font-size: 2.3em;
        margin-bottom: 30px;
    }
    /* Insurance */
    .prp__insurance-top, .prp__insurance-imgs {
        width: 100%;
    }
}