.tag {
    font-family: 'DM Sans', sans-serif;
    background: #FFF5FE;
    color: #FF33C6;
    text-transform: uppercase;
    font-size: 16px;
    padding: 7px 15px;
    border-radius: 125px;
    display: inline-block;
    line-height: 20px;
    letter-spacing: 1.6px;
    font-weight: 800;
    text-align: center;
}

@media only screen and (max-width: 500px) {
    .tag {
        font-size: 13px;
    }
}