.sc {
    background: #F6F3FF url(../../assets/images/pattern-step-card.png) no-repeat;
    background-position: bottom left;
    background-size: 400px;
    border-radius: 24px;
    width: 440px;
    padding: 25px 30px 0px;
    font-size: 16px;
    margin: 40px 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.sc__top {
    display: flex;
}

.sc__left {
    margin-right: 25px;
}

.sc__right {
    flex: 1
}

.sc__no {
    background: white;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.75em;
    color: #4824C1;
    position: relative;
}

.sc__check {
    position: absolute;
    right: 0;
    top: -6px;
    fill: #FF33C6;
}

.sc__heading {
    font-family: "General Sans", sans-serif;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 32px;
    letter-spacing: -0.03em;
    color: #3C268C;
    margin-top: 10px;
    margin-bottom: 20px;
    white-space: pre-line;
}

.sc__desc {
    font-size: 1.1em;
    line-height: 32px;
    color: #6E5DAC;
}

.sc__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sc__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 800px) {
    .sc {
        font-size: 14px;
        margin: 0;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .sc {
        padding-left: 25px;
        padding-right: 25px;
    }
    .sc__no {
        width: 45px;
        height: 45px;
        font-size: 1.5em;
    }
    .sc__heading {
        margin-bottom: 10px;
    }
}