@media only screen and (max-width: 800px) {
    /* Hero */
    .ap-hero__content {
        margin-top: 1pc;
    }
    .ap-hero__sub-heading {
        line-height: 61px;
        font-size: 2.8em;
    }
    .ap-hero__texts {
        width: 80%;
    }
    .ap-hero__img-wrapper:nth-of-type(1) {
        width: 32%;
    }
    .ap-hero__img-wrapper:nth-of-type(2) {
        width: 34%;
    }
    .ap-hero__img-wrapper:nth-of-type(3) {
        width: 27%;
    }
    .ap-hero__img-wrapper:nth-of-type(1) .ap-hero__img-card {
        width: 50px;
    }
    /* Group Heading */
    .ap .g-heading {
        flex-direction: column;
        text-align: center;
    }
    /* Philosophy Card */
    .ap__psy-card {
        width: 320px;
        margin-right: 0;
    }
    /* Philosophy */
    .ap__psy {
        padding: 2pc 0;
    }
    .ap__psy-content {
        width: 80%;
    }
    .ap__psy-top {
        width: 100%;
    }
    .ap__psy-heading, .ap__psy-desc {
        width: 100%;
        max-width: unset;
    }
    .ap__psy-heading {
        margin-bottom: 15px;
    }
    .ap__psy-list {
        justify-content: center;
        margin-top: 3pc;
    }
    /* Doctors */
    .ap__drs {
        padding: 0;
        width: 80%;
    }
    .ap__drs-top {
        width: 100%;
    }
    .ap__drs-list {
        justify-content: center;
    }
    /* Team */
    .ap__team {
        padding: 2pc 0;
    }
    .ap__team-content {
        width: 80%;
    }
    .ap__team-top {
        width: 100%;
    }
    .ap__team-heading, .ap__team-desc, .ap__drs-desc {
        width: 100%;
        max-width: unset;
    }
    .ap__team-heading {
        margin-bottom: 15px;
    }
    .ap__team-list {
        justify-content: center;
    }
    /* With Love */
    .ap__wl {
        margin: 4pc auto;
    }
    .ap__wl-text {
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    .ap {
        font-size: 14px;
    }
    /* Hero */
    .ap-hero__sub-heading {
        line-height: 48px;
        font-size: 2.6em;
    }
    .ap-hero__imgs {
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .ap-hero__img-wrapper:nth-of-type(1) {
        width: 45%;
        height: 150px;
        order: 2;
    }
    .ap-hero__img-wrapper:nth-of-type(2) {
        width: 100%;
        height: 400px;
        order: 1;
        margin-bottom: 2pc;
    }
    .ap-hero__img-wrapper:nth-of-type(3) {
        width: 45%;
        height: 230px;
        order: 3;
    }
    .ap-hero__texts {
        width: 100%;
        text-align: center;
    }
    .ap-hero__heading {
        font-size: 3em;
        line-height: 61px;
        max-width: unset;
    }
    .ap-hero__desc {
        font-size: 1.1em;
    }
    /* VisionMission */
    .ap__vm {
        flex-direction: column;
        margin-top: 1pc;
        margin-bottom: 1pc;
    }
    .ap__vm-item {
        margin-bottom: 3pc;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    /* Philosophy Card */
    .ap__psy-card {
        width: 100%;
        margin-right: 0;
    }
    /* Philosophy */
    .ap__psy-content {
        width: 100%;
    }
    .ap__psy-list {
        flex-direction: column;
        align-items: center;
    }
    /* Doctors */
    .ap__drs-heading {
        font-size: 2.2em;
        line-height: 42px;
    }
    /* Doctor Profile */
    .ap__dp-top {
        flex-direction: column;
        align-items: flex-start;
    }
    .ap__dp-img-wrapper {
        margin-bottom: 20px;
    }
    /* Team */
    .ap__team-content {
        width: 100%;
    }
    /* With Love */
    .ap__wl-text {
        max-width: 250px;
    }
}