.webp.lp--mlp {
    padding-top: 0px;
}

.mlp-header__content {
    padding-top: 40px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3C268C;
    font-size: 16px;
    line-height: 20px;
}

.mlp-header__action-btn {
    background: #FFD66A;
    color: #4824C1;
    border-radius: 15px;
    min-width: 90px;
}

.lp--mlp .hero {
    flex-direction: column;
    align-items: stretch;
}

.lp--mlp .hero__top {
    display: block;
    text-align: center;
}

.lp--mlp .hero__heading {
    font-weight: 600;
    font-size: 3.75em;
    line-height: 72px;
    max-width: 670px;
}

.lp--mlp .hero__subheading {
    margin: 24px 0;
    width: 100%;
    font-weight: 400;
}

/* Cards */

.mlp__cards {
    display: flex;
    justify-content: space-evenly;
}

.mlp__cards-heading {
    margin: 110px auto 30px;
    font-family: "General Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.75em;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.04em;
    max-width: 360px;
    background: -webkit-linear-gradient(180deg, #ff33c6 0%, #7433ff 100%), linear-gradient(180deg, #ff33c6 0%, #7433ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
}

.mlp__cards-heading-txt {
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

.mlp__cards-heading-txt::selection {
    -moz-text-fill-color: #4824C1;
    -webkit-text-fill-color: #4824C1;
}

.mlp__card {
    width: 45%;
    padding: 50px;
    border-radius: 30px;
    margin-bottom: 6pc;
    border-radius: 26.7842px;
    position: relative;
}

.mlp__card-content {
    position: relative;
    z-index: 2;
}

.mlp__illustration {
    width: 250px;
    height: 250px;
    position: absolute;
    bottom: 0;
    right: 20px;
    z-index: 1;
    opacity: 0.5;
}

.mlp__card:first-of-type {
    background-color: #FDEFFF;
    background-image: url(../../../assets/images/pattern-mlp-card-1.png);
    color: #3C268C;
}

.mlp__card:last-of-type {
    background-color: #7E5FF2;
    background-image: url(../../../assets/images/pattern-mlp-card-2.png);
    color: white;
}

.mlp__card-tag {
    border-radius: 111.601px;
    padding: 8px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 26px;
    letter-spacing: -0.02em;
}

.mlp__card:first-of-type .mlp__card-tag {
    background: #FFE0FF;
    border: 0.5px solid #D5CAFF;
}

.mlp__card:last-of-type .mlp__card-tag {
    background: #4824C1;
    border: 0.5px solid #B199F7;
}

.mlp__card-tag img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.mlp__card-heading {
    font-family: "General Sans", sans-serif;
    font-weight: 600;
    font-size: 2.25em;
    line-height: 42px;
    max-width: 305px;
    margin: 2pc 0;
}

.mlp__chat-prompt {
    max-width: 320px;
    margin-bottom: 100px;
    line-height: 24px;
}

.mlp__btn {
    padding: 18px 28px;
    margin-bottom: 100px;
}

.mlp__card:first-of-type .mlp__btn {
    background: #7E5FF2;
    color: white;
}

.mlp__card:last-of-type .mlp__btn {
    background: white;
    color: #7E5FF2;
}

.mlp__btn svg {
    margin-left: 8px;
}

.lp--mlp .footer {
    margin-top: 0;
}

.lp--mlp .footer__logo-cont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.lp--mlp .footer__logo {
    margin-right: 12px;
}

.lp--mlp .footer__action-btn {
    background: #FFD66A;
    color: #4824C1;
    border-radius: 15px;
    min-width: 90px;
}

.lp--mlp .footer__credits {
    margin-top: 15px;
}