* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-weight: 400;
}

a, a:active, a:hover, span {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-decoration: none;
    color: inherit;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
}

button {
    cursor: pointer;
    border: none;
}

*:focus {
    outline: 0 !important;
    border-color: #7E5FF2;
    box-shadow: 0 0 0 3px #7E5FF2 !important;
    border-radius: 6px;
}

*:focus:not(.focus-visible) {
    outline: 0 !important;
    box-shadow: none !important;
}

*::selection {
    background: #96C6FF;
    color: #4824C1;
}