.ap {
    color: #58459C;
    line-height: 34px;
    letter-spacing: -0.01em;
    font-size: 16px;
}

.ap__heading {
    font-family: "General Sans", sans-serif;
    font-weight: bold;
    letter-spacing: -0.06em;
    color: #3C268C;
}

/* Hero */

.ap-hero {
    min-height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../../../assets/images/fog-hero.png) no-repeat;
    background-position: 50% left;
    background-size: 200px 600px;
    position: relative;
}

.ap-hero__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5pc;
    width: 100%;
}

.ap-hero__texts {
    width: 90%;
    max-width: 780px;
    margin-bottom: 60px;
}

.ap-hero__main-heading {
    line-height: 32px;
    font-weight: 500;
    font-size: 1.25em;
    margin-bottom: 16px;
}

.ap-hero__sub-heading {
    line-height: 72px;
    margin-bottom: 15px;
    margin-left: -8px;
    font-weight: 700;
    font-size: 3.6em;
}

.ap-hero__imgs {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 50px;
}

.ap-hero__img-wrapper {
    position: relative;
}

.ap-hero__img-wrapper:nth-of-type(1) {
    width: 25%;
    height: 350px;
}

.ap-hero__img-wrapper:nth-of-type(2) {
    width: 32%;
    height: 460px;
}

.ap-hero__img-wrapper:nth-of-type(3) {
    width: 20%;
    height: 300px;
}

.ap-hero__img {
    border-radius: 32px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ap-hero__img-wrapper:nth-of-type(1) .ap-hero__img-card {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 110px;
}

/* Vision Mission */

.ap__vm {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 4pc;
    margin-bottom: 4pc;
}

.ap__vm-item {
    max-width: 350px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.ap__vm-item-title {
    margin: 5px 0 8px;
    line-height: 60px;
    font-size: 2.8em;
}

.ap__vm-item-desc {
    line-height: 32px;
    font-size: 1.2em;
    font-weight: 500;
}

/* Philosophy */

.ap__psy {
    padding: 3pc 0 5pc;
}

.ap__psy-top {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */
    margin: 0 auto;
    width: 60%;
}

.ap__psy-heading {
    font-size: 2.8em;
    line-height: 52px;
    margin: 20px 0;
}

.ap__psy-desc {
    width: 80%;
    font-size: 1.2em;
    font-weight: 500;
    position: relative;
}

.ap__psy-bubble {
    position: absolute;
    width: 67px;
    top: 20%;
    left: -25%;
}

.ap__psy-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3pc;
}

/* Philosophy Card */

.ap__psy-card {
    border-radius: 30px;
    width: calc(33% - 21px);
    max-width: 360px;
    padding: 40px 30px;
    margin-right: 20px;
    margin-bottom: 30px;
    background-size: contain;
    background-image: url(../../../../assets/images/pattern-philosophy.png);
    background-color: #FFFAFC;
    min-width: 250px;
}

.ap__psy-card-img-wrapper {
    width: 130px;
    height: 130px;
    background: white;
    border-radius: 50%;
    margin-bottom: 20px;
}

.ap__psy-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ap__psy-card-title {
    font-size: 1.9em;
    margin-bottom: 10px;
}

.ap__psy-card-desc {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 10px;
}

/* Doctors */

.ap__drs {
    padding: 3pc 0;
}

.ap__drs-top {
    width: 60%;
    margin: 0 auto 3pc;
    position: relative;
}

.ap__drs-bubble {
    position: absolute;
    width: 67px;
    top: 0;
    right: -10%;
}

.ap__drs-heading {
    line-height: 52px;
    margin: 20px 0;
    font-size: 2.8em;
}

.ap__drs-desc {
    width: 80%;
    font-size: 1.2em;
    font-weight: 500;
}

.ap__drs-list {
    display: flex;
    flex-wrap: wrap;
}

.ap__drs-list .profile .profile__img {
    background: #FFEDDB;
}

.ap__drs-list .profile:nth-of-type(2) .profile__img {
    background: #FFB0F7;
}

.ap__drs-list .profile:nth-of-type(3) .profile__img {
    background: #FFEDDB;
}

.ap__drs-list .profile:nth-of-type(4) .profile__img {
    background: #7E5FF2;
}

.ap__drs-list .profile:nth-of-type(5) .profile__img {
    background: #FFD0CF;
}

.ap__drs-list .profile:nth-of-type(6) .profile__img {
    background: #C2EAFF;
}

.ap__drs-btn {
    margin: 15px 0;
    background: none;
    color: #7E5FF2;
    font-weight: 600;
    font-size: 1em
}

/* Doctor Profile */

.ap__dp-overlay {
    position: fixed;
    background: rgba(126, 95, 242, 0.4);
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ap__dp-content {
    font-size: 16px;
    color: #58459C;
    line-height: 24px;
    letter-spacing: -0.01em;
    width: 90%;
    max-width: 700px;
    max-height: 90vh;
    padding: 30px;
    overflow-y: scroll;
    background: white;
    box-shadow: 0px 150px 70px rgba(60, 38, 140, 0.1);
    backdrop-filter: blur(4px);
    border-radius: 20px;
    position: relative;
}

.ap__dp-close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    background: none;
    color: #3C268C;
}

.ap__dp-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
}

.ap__dp-top-right {
    flex: 1;
}

.ap__dp-img-wrapper {
    width: 110px;
    height: 110px;
    margin-right: 18px;
}

.ap__dp-img {
    border-radius: 50%;
    background: #7E5FF2;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ap__dp-name {
    font-size: 1.4em;
    line-height: 28px;
    margin-bottom: 3px;
}

.ap__dp-location {
    color: #7E5FF2;
    font-size: 1.1em;
    font-weight: 500;
}

.ap__dp-props {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.ap__dp-prop {
    margin-right: 20px;
    margin-bottom: 10px;
}

.ap__dp-prop svg {
    margin-right: 8px;
    margin-bottom: -4px;
}

.ap__dp-about {
    margin-bottom: 30px;
}

.ap__dp-hobbies {
    margin-bottom: 15px;
}

.ap__dp-section-heading {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 12px;
    letter-spacing: 0.1em;
}

/* Team */

.ap__team {
    padding: 5pc 0;
}

.ap__team-top {
    width: 60%;
    margin: 0 auto 3pc;
}

.ap__team-bubble {
    position: absolute;
    width: 67px;
    top: -100%;
    right: 50%;
    left: 50%;
}

.ap__team-heading {
    font-size: 2.8em;
    line-height: 52px;
    margin: 12px 15px 20px 0;
}

.ap__team-desc {
    width: 80%;
    font-size: 1.2em;
    font-weight: 500;
}

.ap__team-list {
    display: flex;
    flex-wrap: wrap;
}

.ap__team-list .profile .profile__img {
    background: #FFEDDB;
}

.ap__team-list .profile:nth-of-type(2) .profile__img {
    background: #FFB0F7;
}

.ap__team-list .profile:nth-of-type(3) .profile__img {
    background: #FFEDDB;
}

.ap__team-list .profile:nth-of-type(4) .profile__img {
    background: #7E5FF2;
}

/* With Love */

.ap__wl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2pc auto 4pc;
}

.ap__wl-text {
    font-family: "General Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2em;
    line-height: 52px;
    letter-spacing: -0.03em;
    color: #B1A8D1;
}

.ap__wl-icon {
    width: 80px;
    margin-bottom: 15px;
}

.ap__wl-highlight {
    color: #3C268C;
}