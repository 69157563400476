.profile {
    font-size: 16px;
    color: #58459C;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 25%;
    min-width: 220px;
}

.profile__img-wrapper {
    width: 190px;
    height: 190px;
    margin-bottom: 16px;
    border-radius: 50%;
}

.profile__img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 15px;
}

.profile__ptext {
    font-family: "General Sans", sans-serif;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 26px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #3C268C;
    margin-bottom: 8px;
}

.profile__stext {
    font-size: 1em;
    line-height: 19px;
    letter-spacing: -0.01em;
}

@media only screen and (max-width: 800px) {
    .profile {
        font-size: 14px;
        min-width: 200px;
        text-align: center;
    }
    .profile__img-wrapper {
        width: 120px;
        height: 120px;
    }
}