.avatar-p {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid transparent;
    position: relative;
    margin-bottom: 20px;
}

.avatar-p:not(:last-of-type) {
    margin-right: 20px;
}

.avatar-p__img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #D3E5FF;
}

.avatar-p__circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
}

.avatar-p__circle {
    opacity: 0;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0s;
}

.avatar-p--active .avatar-p__circle {
    opacity: 1;
}