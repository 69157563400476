@media screen and (max-width: 800px) {
    .legal {
        font-size: 14px;
    }
    .legal__top-heading {
        font-size: 3em;
        line-height: 61px;
    }
    /*** Legal Content  ***/
    .legal__content {
        width: 95%;
        flex-direction: column;
    }
    /* Legal Content - Nav  */
    .legal__nav-wrapper {
        padding-right: 0;
        padding-bottom: 24px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #ECE6FF;
    }
    /* Legal Content - Doc Heading  */
    .legal__doc {
        padding-left: 0;
        padding-top: 24px;
    }
    /* Legal Content - Contact  */
    .legal__contact {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .legal__header {
        padding: 25px;
    }
    .legal__top-body {
        margin: 0 auto;
        padding-bottom: 1pc;
    }
    .legal__top-heading {
        font-size: 2.75em;
        line-height: 50px;
        padding-bottom: 0;
    }
    .legal__top-img {
        width: 150px;
        height: 150px;
    }
    h1.legal__doc-heading {
        font-size: 2.5em;
    }
    /* Mobile sticky header */
    .legal__sticky-header-mobile.show {
        background: rgba(255, 255, 255, 0.7);
        transform: translate3d(0, 0, 0);
    }
    .legal__sticky-header-mobile.show--nav {
        background: white;
        transform: translateY( 0);
    }
    .legal__bcrumbs-btn {
        background: transparent;
        padding: 0 5px 0 25px;
    }
    .legal__bcrumbs-btn svg {
        width: 16px;
        height: 16px;
    }
    /* Legal Content   */
    .legal__content {
        width: 100%;
    }
    /* Legal Content - Nav  */
    .legal__nav-heading {
        display: none;
    }
    .legal__nav {
        position: fixed;
        z-index: 10;
        top: 130px;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        padding: 44px 28px;
        font-size: 1.2em;
        transform: translateY(100%);
        transition: transform 0.4s;
    }
    .legal__nav.show {
        transform: translateY(0);
    }
    .legal__nav::before {
        content: "";
        width: calc(100% - 56px);
        position: absolute;
        top: 10px;
        background: #E4DDFF;
        height: 1px;
        display: block;
    }
    .legal__nav-link {
        margin-bottom: 20px;
    }
    .legal__bcrumbs {
        display: flex;
    }
    /* Legal Content - Doc body  */
    .legal__toggle-tbl-btn {
        padding: 11px;
    }
    /* Banner */
    .legal__banner--heading .legal__banner-header {
        border-bottom: 1px solid #FFC2C9;
        padding-bottom: 16px;
    }
}